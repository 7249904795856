@import "../../variables";

.ci-container {
  .input {
    width: 100%;
    margin-bottom: 10px;
  }

  .flex-row.city-zip {
    margin: 0;

    .input {
      flex: 2;

      &:last-of-type {
        flex: 1;
        margin-right: 0;
      }
    }
  }

  .ci-input-item {
    margin-bottom: 10px;
    .checkbox {
      display: flex;
      align-items: center;

      input {
        flex-grow: 0;
        margin-right: 10px;
      }

      span {
        flex: 1;
      }
    }
  }

  .ci-section {
    margin-top: 30px;
  }

  h2 {
    margin-bottom: 10px;
  }

  .StripeElement {
    border: 1px solid $lightgray;
    box-shadow: none;
    transition: none;
    height: $inputHeight;

    &--focus {
      border: 1px solid $blue;
      box-shadow: 0 0 0 1px $blue;
    }
  }

  .ci-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }
}


.ci-section-title {
  font-size: 18px;
  margin-bottom: 4px;
}

.ci-item-label {
  font-size: 14px;
  margin-bottom: 6px;
}

.ci-region-container {
  margin-top: 8px;
}

.ci-section {
  margin-top: 10px;
}

.ci-back-button-icon {
  font-size: 42px;
  vertical-align: sub;
}

.ci-info-label {
  text-align: left;
  margin-top: 0 !important;
}

.ci-info-item {
  text-align: right;
  margin-top: 0 !important;
}

.ci-total {
  font-weight: bold;
  text-align: left;
}

.ci-total-item {
  font-weight: bold;
  text-align: right;
}