@import "../../variables";

.loader-pane {
  background: $superlightgray;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .loader-container {
    justify-content: center;
    padding: 0;

    .loader-animated {
      height: 200px;
      width: 116px;
      margin: 0;
    }
  }

  p {
    margin-top: 20px;
  }
}

.child-container {
  height: 639px;
  overflow: hidden;
  min-height: 639px;
  max-height: 639px;
}
