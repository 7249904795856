@import "../../variables";

.cls-1 {
  fill: none;
  stroke: $lightgray;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-width: 15px;
  stroke-dasharray: 4964.5025px;
  animation: dash 1s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 9929.005px;
  }
}

.loader-animated {
  position: relative;
  margin: 20px 35px;
  width: auto;
  flex-shrink: 0;
  height: calc(50vh - 40px);
  width: 28.85vh;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 100vw;
  padding: 30px 15px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  &.fix {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    padding: 0;

    .loader-animated {
      position: relative;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}
