@import "../../variables";

$navColor: #282c34;

.header {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  z-index: 100;
  font-size: 14px;
  flex-wrap: nowrap;
  order: 1;

  .title a {
    text-decoration: none;
    font-size: 24px;
  }

  .header-item {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;

    align-self: stretch;
    margin-right: 16px;
  }

  .header-item-full {
    flex: 1 1 auto;
  }

  .header-link {
    white-space: nowrap;
    text-decoration: none;
    font-family: "IBM Plex Sans", sans-serif;
    color: $darkgray;
    font-weight: normal;
    font-size: 18px;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
}

.popover-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $white;
  color: $black;
  padding: 60px 30px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 50;

  &.active {
    height: 100vh;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .loader-container {
    width: 100%;
  }
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 640px;
  width: 100%;
  margin: 0 auto;
}

.group-row {
  display: flex;
  align-items: center;
  width: 100%;

  > *:not(:first-child) {
    margin-left: 10px;
  }
}

.search-input {
  position: relative;
  background: $white;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: 24px;
  font-weight: bold;
  border-bottom: 2px solid darken($white, 20%);
  box-shadow: none;
  box-sizing: border-box;
  font-family: inherit;
  flex-grow: 1;

  &:focus {
    border-bottom: 2px solid $blue;
  }
}

.button {
  flex-grow: 0;
  flex-shrink: 0;

  .red-indicator {
    background: darken($red, 5%);
    color: $white;
    font-size: 12px;
    line-height: 1em;
    padding: 1px 5px;
    border-radius: 100px;
    position: absolute;
    top: -4px;
    right: -4px;
  }
}

.search-results {
  list-style: none;
  padding: 0;
  text-align: left;
  font-weight: normal;
  flex: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  width: 100%;
  margin-top: 30px;

  .result {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $black;
    width: 100%;
    padding: 10px 0;
    font-size: 24px;
    font-weight: 400;

    li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      // background: $superlightgray;
      color: $blue;
      text-decoration: underline;
    }
  }
}
