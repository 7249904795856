@import "../../variables";

.wiki-list-item {
  border-bottom: 1px solid $lightgray;
  padding: 30px;
  display: flex;
  flex: 1;
  align-items: center;

  &:last-of-type {
    border-bottom: 0;
  }
}

.wiki-list-left {
  max-width: 100%;

  h1 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .link-button {
    margin: 0;
  }
  &.no-link-padding .link-button {
    margin-left: 0;
  }
}

.wiki-list-right {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  width: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
  height: 120px;
  overflow: hidden;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.wiki-list-item-number {
  font-weight: normal;
  width: 40px;
  display: inline-block;
}

.wiki-shirt-preview-small {
  background: $superlightgray;
  width: 120px;
  height: 120px;
  border: 1px solid $lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-left: 10px;
}

.wiki-image-card {
  border: 1px solid $lightgray;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px;

  .wiki-image-background {
    width: 100%;
    height: 173px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 60px;
    position: relative;

    .wiki-shirt-preview-small {
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .wiki-image-card-content {
    text-align: center;
    padding: 0 30px;
  }

  h1 {
    font-size: 24px;
    margin: 0;
  }
}
