@import "../../variables";

.page-card {
  border: 1px solid $lightgray;
  width: 420px;
  min-width: 420px;
  height: 513px;
  margin: 0 15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  .card-content {
    overflow-y: scroll;
    flex: 1;
    padding: 30px;
  }

  .card-footer {
    padding: 30px;
    flex: 0;
    border-top: 1px solid $lightgray;

    .link-button {
      margin: 0;
    }
  }
}

.list-container {
  width: 100%;
}

.wiki-list-loader {
  height: 180px;
  border-bottom: 1px solid $lightgray;
  padding: 30px;
  display: flex;
  align-items: center;
}
