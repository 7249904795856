@import "../../variables";

@function sqrt($r) {
  $x0: 1; // initial value
  $solution: false;
 
  @for $i from 1 through 10 {
    @if abs(2 * $x0) < 0,00000000000001 { // Don't want to divide by a number smaller than this
      $solution: false;
    }
 
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0) !global;
 
    @if ( abs($x1 - $x0) / abs($x1)) < 0,0000001 { // 7 digit accuracy is desired
      $solution: true;
    }
 
    $x0: $x1;
  }
 
  @if $solution == true {
    // If $r is negative, then the output will be multiplied with <a href="http://en.wikipedia.org/wiki/Imaginary_number">i = √-1</a>
    // (√xy = √x√y) => √-$r = √-1√$r
    @if $r < 0 {
      @return $x1 #{i};
    }
    @else {
      @return $x1;
    }
  }
  @else {
    @return "No solution";
  }
}

$width: 12;
$pseudoWidth: sqrt(2 * ($width * $width));
$pseudoWidthHalfPx: 0px - ($pseudoWidth / 2);

:root {
  --yOffset: 0px;
  --arrowOffset: -50%;
}

@keyframes grow-left {
  0% {
    opacity: 0;
    transform: translateY(calc(-50% - var(--yOffset))) scale(0);
  }

  100% {
    opacity: 1;
    transform: translateY(calc(-50% - var(--yOffset))) scale(1);
  }
}

@keyframes grow-right {
  0% {
    opacity: 0;
    transform: translateY(calc(-50% - var(--yOffset))) scale(0);
  }

  100% {
    opacity: 1;
    transform: translateY(calc(-50% - var(--yOffset))) scale(1);
  }
}

@keyframes grow-bottom {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.tooltip {
  padding: 20px;
  background: $black;
  box-shadow: $raisedShadow;
  position: absolute;
  z-index: 1;
  border-radius: 2px;
  box-sizing: border-box;
  opacity: 0;
  white-space: nowrap;

  &.anchor {
    &-left {
      right: calc(100% + 24px);
      top: 50%;
      transform-origin: right center;
      transform: translateY(calc(-50% + var(--yOffset))) scale(1);

      &::after {
        right: 0px - floor($pseudoWidth / 2);
        bottom: calc(0px - var(--arrowOffset));
        // bottom: 0;
        box-shadow: 3px 3px 20px $darkgray;
        border-radius: 0 2px 0 0;
      }
    }

    &-right {
      left: calc(100% + 24px);
      top: 50%;
      transform-origin: left center;
      transform: translateY(calc(-50% + var(--yOffset))) scale(1);

      &::after {
        left: 0px - floor($pseudoWidth / 2);
        bottom: calc(0px - var(--arrowOffset));
        // bottom: 0;
        box-shadow: 3px 3px 20px $darkgray;
        border-radius: 0 2px 0 0;
      }
    }

    &-bottom {
      left: 50%;
      top: calc(100% + 24px);
      transform-origin: center top;
      transform: translateX(-50%) scale(1);
      text-align: center;

      &::after {
        top: 0px - floor($pseudoWidth);
        left: calc(50% + #{$pseudoWidthHalfPx});
        border-radius: 2px 0 0 0;
      }
    }
  }

  h1, p {
    color: $white;
  }

  h1 {
    font-size: 20px;
    margin: 0;
  }

  .link-button {
    margin: 0;
    margin-top: 10px;
    color: $white;

    &:hover {
      background: $black;
    }
  }

  * {
    position: relative;
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black;
    z-index: 0;
    border-radius: 2px;
  }

  &::after {
    content: "";
    position: absolute;
    height: $pseudoWidth + 0px;
    width: $pseudoWidth + 0px;
    pointer-events: none;
    background: $black;
    z-index: -1;
    transform-origin: center center;
    transform: rotate(45deg) translate(35%, 35%);
  }
}