.featured-tee {
  display: inline-block;
  padding: 0;
}

.featured-gallery {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;

  .featured-title {
    padding: 20px;
    padding-left: 80px;
    text-align: left;

    @media only screen and (max-width: 960px) {
      padding-left: 60px;
    }
  }
}
