@import "../../variables";


.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 20;
  display: flex;
}

.fab {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  box-shadow: $raisedShadow;
  font-size: 40px;
  margin: 0;
  transition: transform 0.1s ease;
  user-select: none;

  &.pressed {
    transform-origin: center center;
    transform: scale(0.75);
  }

  &.ghost {
    position: relative;
    bottom: auto;
    right: auto;
    margin: 0;
    box-shadow: none;
    opacity: 0;
    user-select: none;
  }

  span {
    margin: 0;
  }
}