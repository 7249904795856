.order-status-container {
  text-align: center;
  margin-top: 48px;
}

.order-status-info-container {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: left;
  width: 60%;
}

.order-status-title {
  font-size: 1.2em;
  margin-top: 120px;
}

.order-status-preview-image {
  width: 100%;
}

.order-status-failed {
  border: 1px solid #fa755a;
  border-radius: 4px;
  padding: 8px;
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.order-status-text {
  margin-left: 6px;
  margin-top: 24px;
}

.order-status-description {
  margin-left: 6px;
  margin-top: 6px;
}

.order-status-shipments {
  margin: 14px;
}

.order-status-shipment {
  margin-top: 6px;
}

.order-status-shipment-url {
  font-weight: bold;
  text-decoration: underline;
}