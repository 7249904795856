.checkout-section {
  margin-top: 20px;

  h2 {
    font-size: 1rem;
  }
}

.checkout-button-container {
  display: flex;
  margin-top: 20px;
}

.checkout {
  p {
    margin: 0;
  }
  
  h2 {
    margin: 0;
  }
}