.page-content {
  display: block;
  order: 2;

  position: relative;
  flex-grow: 1;
  height: 10px;

  .page {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
}
