@import "../../variables";

.all-radio-options {
  display: inline-block;
  position: relative;
}

.shipping-description {
  padding: 20px 0px;
}

.shipping-option-container {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px 20px;
  border-radius: 10px;
  

  &.checked {
    background: rgba($blue, 0.1);
    color: $blue;

    label .shipping-option-label .shipping-option-name {
      color: $blue;
    }
  }

  label {
    display: flex;
    width: 100%;
    align-items: center;

    input {
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }

    .shipping-option-label {
      flex-grow: 1;

      .shipping-option-name {
        color: $darkgray;
      }

      .shipping-option-rate {
        font-size: 1.5em;
      }
    }
  }
}


/* General declarations to make custom radios feel nice to click on. */
.shipping-option-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  
  /* Input's box-sizing is set to border-box by default. */
  box-sizing: content-box;
  cursor: pointer;
  outline: none;
  position: relative;
}

.shipping-option-container input[type="radio"] {
  border-radius: 100%;
  border: 1px solid $lightgray;
  box-shadow: inset 0 0 0 2px $white;
  transition: all .1s;
  box-sizing: border-box;
}
.shipping-option-container input[type="radio"]:checked {
  background: $blue;
  border: 2px solid $blue;
  box-shadow: inset 0 0 0 3px $white;
}

.shipping-option-label:hover {
  cursor: pointer;
}

.shipping-checkout-button-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.shipping-options-loading-message {
  margin-left: auto;
  margin-right: auto;
  margin: 8px;
}
