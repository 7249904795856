@import "../../variables";

.purchase-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.purchase-info-title {
  font-size: 16px;
  font-weight: bold;
  text-align: right;
}

.purchase-info-container {

  &:not(:last-of-type) {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $lightgray;
  }

  .shirt-item {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 30px;
  }

  .column {
    display: flex;
    width: 0;
    flex-grow: 1;
    flex-direction: column;
  }
}

.flex-row {
  margin-top: 10px;

  .select, .input {
    flex-grow: 1;
    margin-right: 10px;
  }

  .link-button {
    margin: 0;
    height: $inputHeight;
  }

  &.space-between {
    justify-content: space-between;
    align-items: center;
  }
}

.link-button {
  margin: 0;
  margin-top: 10px;
  margin-right: auto;
}

.purchase-small-select {
  max-width: 72px;
  margin-left: auto;
}

.purchase-info-item {
  margin-top: 10px;

  label {
    font-weight: 700;
    color: $darkgray;
  }
}

.purchase-info-amount {
  padding-top: 6px;
}

.purchase-add-another {
  color: #5C196B;
  font-weight: bold;
  margin: 10px;
  margin-left: 20px;
}
.purchase-add-another:hover {
  color: #4C095B;
  cursor: pointer;
}

.purchase-subtotal {
  text-align: center;
}

.purchase-remove-item {
  font-weight: bold;
  text-align: center;
}

.purchase-remove-item:hover {
  cursor: pointer;
}

.purchase-subtotal-container {
  border: 1px solid black;
  border-radius: 2px;
  padding: 10px;
}

.purchase-subtotal-title {
  text-align: center;
}

.purchase-continue-button-container {
  margin-right: 8.3333%;
  margin-top: 16px;
  text-align: right;
}

.purchase-controls-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
