@import "../../variables";

// .landing {
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;
// }

.landing-item {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 30px 0px;
  max-width: 100vw;
  position: relative;

  &.no-flex {
    display: block;
  }

  &.landing-item-white-text {
    color: $white;
  }

  .link-button {
    margin-top: 0;
  }

  &.landing-card {
    display: flex;

    @media only screen and (min-width: 961px) {
      padding: 120px 0;
    }

    &.align-right {
      justify-content: flex-end;
    }
  }
}

.landing-hero {
  display: flex;
  max-width: 100vw;

  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.landing-item-shirt-background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;

  &.background-desktop-only {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &.landing-thumb {
    position: relative;
    width: calc(100% + 60px);
    height: 240px;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -30px;
    margin-bottom: 30px;
    display: none;

    @media only screen and (max-width: 960px) {
      display: block;
    }
  }
}

.background-desktop-only {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.landing-item-explore-background {
  // background-image: url('/unsplash-shirt-rack.jpg');
  // background-image: url("/unsplash-explore.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.landing-item-left {
  text-align: left;
  padding: 30px;
  width: 440px;
  vertical-align: top;
  flex: none;

  @media only screen and (max-width: 960px) {
    // padding: 4px;
    // padding-top: 40px;
    max-width: 100vw;
    width: 100%;
    // max-width: 180px;
    .landing-detail,
    .landing-title,
    .landing-text {
      font-size: 16px;
    }
  }
}

.landing-left-box {
  position: relative;
  margin: 0 120px;
  background: $white;
  border: 1px solid $lightgray;
  border-radius: 4px;
  max-width: calc(100vw - 60px);
  overflow: hidden;

  @media only screen and (max-width: 960px) {
    margin: 0 30px;
  }
}

.landing-detail {
  font-size: 24px;
  font-weight: bold;
}

.landing-title {
  font-size: 24px;
  margin-bottom: 1em;
}

.landing-text {
  font-size: 24px;
  margin-bottom: 1em;
}

.landing-footnote {
  margin-top: 2em;
  font-size: 12px;
  color: $darkgray;
}

.landing-gallery {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 15px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  width: 100%;

  // &.preview {
  //   padding: 0;
  //   height: 10vh;
  //   width: auto;
  // }

  // .thumbnail {
  //   height: 100%;
  //   margin-right: 2px;
  // }

  // &::after {
  //   content: "";
  //   min-width: 15px;
  //   height: 1px;
  //   display: block;
  // }
}

.no-padding-mobile {
  @media only screen and (max-width: 960px) {
    padding: 0;
  }
}

.outline-container {
  position: relative;
  border: 1px dashed $lightgray;

  &:not(.no-flex) {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    width: 10px;

    @media only screen and (max-width: 960px) {
      width: calc(100vw - 30px);
      align-self: flex-end;
    }
  }

  &.no-flex {
    padding: 30px 15px;
  }

  .outline-container-label {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 22px;
    transform: translateY(-50%);
    transform-origin: top right;
    text-align: right;
    background: $white;
    padding: 0 8px;
    color: $darkgray;
    // text-transform: uppercase;
    font-weight: bold;
    // letter-spacing: 2px;
  }
}

.daily-container {
  display: flex;
  padding: 30px;

  &.no-padding-top {
    padding-top: 0;
  }

  .outline-container {
    margin-right: 30px;
    display: flex;
  }
}

.trending-articles {
  border: 1px solid $lightgray;
  border-radius: 4px;
  width: 100%;
}

.daily-tag {
  background: $lightgray;
  color: $darkgray;
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  text-transform: uppercase;
  margin: 30px 30px 0;
  font-weight: bold;
}
