@import "../../variables";

.toggle {
  margin: 10px 0px;

  /* The switch - the box around the slider */
  .togglebox {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }

  /* Hide default HTML checkbox */
  .togglebox input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightgray;
    -webkit-transition: 250ms;
    transition: 250ms;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 3px;
    background-color: $white;
    -webkit-transition: 250ms;
    transition: 250ms;
  }

  input:checked + .slider {
    background-color: $blue;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .description {
    display: inline-block;
    margin-left: 4px;
    vertical-align: sub;
  }
}