@import "variables";

button,
a.button {
  position: relative;
  background: $black;
  color: $white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  margin: 0;
  border: 1px solid transparent;
  outline: none;
  border-radius: 2px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  box-sizing: border-box;

  &:not(.link-button) {
    text-decoration: none;
  }

  &.link-button:focus {
    border: 1px solid $blue !important;
    box-shadow: 0 0 0 1px $blue;
  }

  &:hover {
    cursor: pointer;
  }

  &.icon-button {
    padding: 0;
    width: 40px;
  }

  &.inverse {
    background: $white;
    color: $black;
  }

  &.hollow {
    border: 2px solid $black;
  }

  span {
    margin-right: 5px;
  }

  &.round {
    border-radius: 50%;
    width: 40px;
  }
}

// .page-content {
//   position: relative;
//   flex-grow: 1;
//   height: 10px;
// }

// .page {
//   height: 100%;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch;

//   &.centered {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     text-align: center;
//   }
// }

.titleRow {
  padding: 0 30px;

  .link-button {
    margin-top: 30px;
  }
}

.wikiEntry {
  border-bottom: 1px dashed $lightgray;
}

.gallery {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  padding: 30px 15px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;

  &.preview {
    padding: 0;
    height: 10vh;
    width: auto;
  }

  .thumbnail {
    height: 100%;
    margin-right: 2px;
  }

  &::after {
    content: "";
    min-width: 15px;
    height: 1px;
    display: block;
  }
}

.tag {
  background: $black;
  color: $white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
  height: 30px;
  box-sizing: border-box;
}

.page-details {
  padding: 0 30px 140px;

  &.no-padding {
    padding: 0;
  }
}

.mt10 {
  margin-top: 10px;
}

h2,
p,
a {
  margin: 0;
  line-height: 1.6em;
}

.gray {
  color: $darkgray;
}

.blue {
  color: $blue;
}

p {
  font-family: "IBM Plex Sans", sans-serif;
}

a {
  color: inherit;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }

  &.blue:hover {
    color: $darkblue;
  }
}

.link-button {
  padding: 4px 10px 4px 10px;
  border: 1px solid $lightgray;
  border-radius: 4px;
  margin-bottom: 10px;
  display: inline-flex;
  color: $blue;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
  text-decoration: none;
  height: 35px;
  background: $white;

  &:focus {
    border: 1px solid $blue;
    box-shadow: 0 0 0 1px $blue;
  }

  &.icon-left {
    padding-left: 8px;

    svg {
      margin-right: 4px;
    }
  }

  &.icon-right {
    padding-right: 8px;

    svg {
      margin-left: 4px;
    }
  }

  &:hover {
    cursor: pointer;
    color: $darkblue;
    text-decoration: underline;
    background: $superlightgray;

    span {
      color: $darkblue;
    }

    .icon {
      fill: $darkblue;
    }
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.5;
  }

  svg {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }

  span {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    fill: $blue;
  }

  span {
    display: inline;
  }
}

.bottom-nav {
  justify-content: center;
  z-index: 9;

  button {
    flex: 1;
    max-width: 300px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.help-text {
  color: lighten($black, 50%);
  font-size: 10px;
  padding-top: 8px;
}

p.large {
  font-size: 1.5em;
}

.flex-row {
  display: flex;
  justify-content: center;

  .link-button {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.fire {
  user-select: none;
  z-index: 19;
  position: absolute;
  transform: translate(0px, 0px);
}

.m0 {
  margin: 0 !important;
}

.mlauto {
  margin-left: auto;
}
