// @import "../../styles/variables";

.loader-container {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.loader-container-with-margin {
  margin-top: 120px;
  margin-bottom: 200px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100%;
  position: relative;

  .spinner {
    width: 46px;
    height: 46px;
    border-radius: 100%;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // margin-top: 80px;
    // margin-bottom: 80px;

    border: 2px solid #21243d; // $lightBlue;
    border-radius: 0;
    animation: rotate 3s infinite;
    &:after, &:before{
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 32px;
      height: 32px;
      border: 2px solid #21243d;
      animation: rotate 3s infinite reverse;
    }
    &:before{
      border-color: #21243d;
      left: 10px;
      top: 10px;
      width: 22px;
      height: 22px;
      animation: rotate 2s infinite;
    }
  }
}



.sofloo-loader-container {
  animation: soflooLoaderContainerAnim 2s alternate infinite ease-in-out;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center;
}
.sofloo-loader {
  animation: soflooLoaderInnerBoxAnim 2s alternate infinite ease-in-out;
  display: inline-block;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  width: 32px;
}
/* outline: 1px solid transparent; */

.sofloo-loader-outer-box {
  background-color: #5C196B;
  border-radius: 50%;
  height: 32px;
  position: absolute;
  width: 32px;
  animation: soflooLoaderOuterBoxAnim 0.5s;
}
.sofloo-loader-inner-box {
  background-color: #FFDA8E;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: absolute;
  z-index: 1;
  animation: soflooLoaderInnerBoxAnim 1.25s alternate infinite ease-in-out;
}
@keyframes soflooLoaderOuterBoxAnim {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}

@keyframes soflooLoaderInnerBoxAnim {
  0% {transform: scale(0);}
  100% {transform: scale(0.9);}
}

@keyframes soflooLoaderContainerAnim {
  /* 0% {transform: rotate(45deg);}
  100% {transform: rotate(135deg);} */
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@keyframes soflooLoaderAnim {
  0% {transform: scale(0);}
  100% {transform: scale(1);}
}