@import "../../variables";

.shirt-page-container {
  // flex: 1;
  position: relative;
}

.shirt-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;

  flex-grow: 1;
  overflow-y: scroll;
  
  align-items: flex-start;

  @media only screen and (max-width : 960px) {
    flex-direction: column;
    justify-content: start;
  }

  .shirt-preview {
    background: $superlightgray;
    padding: 20px;
    flex-grow: 1;
    flex-shrink: 0;
    width: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;

    @media only screen and (max-width : 960px) {
      width: 100%;
    }
  }

  .shirt-title {
    padding: 30px;
    box-sizing: border-box;
    width: 460px;
    margin: 0;
    flex-grow: 0;
    border-left: 1px solid $lightgray;
    height: 100%;
    order: 3;

    @media only screen and (max-width : 960px) {
      border-bottom: 1px solid $lightgray;
      border-left: none;
      width: 100%;
      height: auto;
      order: 1;
    }

    .link-button {
      margin-top: 0;
      margin-bottom: 10px;
    }

    h1 {
      margin: 0;
      font-size: 20px;
      line-height: 1.6;
    }

    p {
      margin: 0;
    }

    .flex-row {
      display: flex;
      margin-top: 10px;
      width: 100%;
      align-items: center;

      @media (max-width: 576px) {
        flex-wrap: wrap;
      }

      .column {
        @media (max-width: 576px) {
          flex-wrap: wrap;
        }
      }

      .column.grow {
        flex-grow: 1;
      }

      .column:first-child {
        @media (max-width: 576px) {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
          padding-bottom: 0px;
          margin-bottom: 6px;
          // border-bottom: 1px solid $lightgray;
          width: 100%;
          text-align: left;
        }

        margin-right: 30px;
        padding-right: 30px;
        border-right: 1px solid $lightgray;
        height: 100%;
      }

      .select {
        height: 40px;
        flex-grow: 1;

        .select__control {
          height: 40px;
        }
      }
    }
  }
}

.shirt-options-container {
  float: right;
  position: relative;
}

.shirt-options-button {
  fill: $darkgray;
}

.shirt-options-button:hover {
  cursor: pointer;
  fill: $black;
}

.shirt-options-dropdown {
  background-color: white;
  position: absolute;
  right: 0;
  top: 24px;
  // box-shadow: 0px 0px 3px 3px $lightgray;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-align: right;
  z-index: 20;
}

.shirt-dropdown-item {
  padding: 12px 16px;
  text-align: right;
}

.shirt-dropdown-item:hover {
  background-color: #fafafa;
  cursor: pointer;
}


.shirt-page-empty {
  display: flex;
  flex-direction: column;
  padding: 24px;
}
