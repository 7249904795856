@import "../../variables";

.page-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.page-container.LIGHT {
  background: $white;
  color: $black;

  .nav {
    background: $white;
    color: $black;
    box-shadow: 0 1px 2px $lightgray;

    &.bottom-nav {
      box-shadow: 0 -1px 2px $lightgray;
    }
  }

  button,
  a.button {
    background: $black;
    border-color: $black;
    color: $white;
    .icon {
      fill: $white;
    }
    &.link-button {
      border-color: $lightgray;
      color: $blue;
      .icon {
        fill: $blue;
      }
      &.red {
        color: $red;
        .icon {
          fill: $red;
        }
      }
    }
    &.inverse {
      background: $white;
      border-color: $white;
      color: $black;
      .icon {
        fill: $black;
      }
    }
    &.hollow {
      border-color: $black;
    }
    &.favorite .icon {
      fill: $red;
    }
  }
  .link-button {
    color: $blue;
    background: $white;
    &:hover {
      background: $superlightgray;
    }
  }
}

.page-container.DARK {
  background: $black;
  color: $white;

  .nav {
    background: $black;
    color: $white;
    box-shadow: 0 1px 2px $darkgray;
    &.bottom-nav {
      box-shadow: 0 -1px 2px $darkgray;
    }
  }
  button {
    background: $white;
    color: $black;
    .icon {
      fill: $black;
    }
    &.inverse {
      background: $black;
      color: $white;
      .icon {
        fill: $white;
      }
    }
    &.hollow {
      border-color: $white;
    }
    &.favorite .icon {
      fill: $red;
    }
  }
}
