@import "../../variables";

.pay-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.pay {
  color: $black;
  padding: 30px;
  max-width: 640px;
  margin: 0 auto;
}

.pay-back-button {
  color: #5C196B;
  font-size: 14px;
  position: absolute;
  top: 16px;
  left: 20px;
}

.pay-back-button:hover {
  cursor: pointer;
}

.pay-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  margin-top: 18px;
  text-align: center;
}

.pay-background {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pay-error-message {
  background-color: red;
  border-radius: 4px;
  color: white;
  margin: 20px;
  margin-bottom: 10px;
  padding: 10px;
}
