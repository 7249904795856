.mockup {
  padding: 10px;
  position: relative;
  height: 100%;

  canvas {
    height: 100%;
    margin: 0;
    position: relative;
  }
}
