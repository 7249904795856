.terms {
  color: #282c34;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  order: 2;
  padding: 32px;
  right: 0;
  text-align: left;
  margin-top: 2em;

  p {
    margin-top: 0.25em;
  }

  h2 {
    margin-top: 0.5em;
  }
}
