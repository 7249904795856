@import "../../variables";

.shirt-item {
  height: 100%;
  // position: relative;
  margin: 0 15px;
  text-align: center;
  background: $superlightgray;
  box-sizing: border-box;
  border: 1px solid $lightgray;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border-radius: 4px;
  overflow: hidden;

  &.fixed {
    width: 251px;
    .preview {
      height: 420px;
    }
  }
  &:not(.fixed) {
    width: calc(28.85vh + 40px);

    .preview {
      height: 50vh;
    }
  }

  &.hide-box {
    background: transparent;
    border-color: transparent;
    pointer-events: none;
    user-select: none;

    .label {
      opacity: 0;
    }

    .preview-action-row {
      opacity: 0;
    }
  }

  &:hover {
    cursor: pointer;

    .label {
      color: $blue;
      text-decoration: underline;

      .gray {
        color: $blue;
      }
    }
  }

  &.small {
    margin: 0;
    width: 100px;
    .preview {
      height: 100px;
      padding: 10px;
    }
  }

  .preview {
    position: relative;

    button {
      border: 1px solid $lightgray;
    }

    .preview-action-row {
      position: absolute;
      display: flex;
      bottom: 10px;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin: 0;
      padding-right: 10px;
      box-sizing: border-box;

      .tag {
        margin-right: auto;
      }
    }

    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .label {
    padding: 20px;
    border-top: 1px solid $lightgray;
    text-align: left;
    color: $black;
    background: $white;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    p,
    a {
      font-weight: 400;
      text-decoration: none;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:last-child::after {
    content: "";
  }
}
